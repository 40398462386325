<template>
  <div>
    <InventoryTable
      :title="tLabel('All visits')"
      :headers="headers"
      :defaultSort="defaultSort"
      :excludeButtons="{ unitNumber: true, sysTypes: true, tipoUnit: true, voyages: true }"
    >
      <template v-slot:filterButtons="{ filters }">
        <v-col cols="2">
          <v-text-field :label="tLabel('Unit number')" v-model="filters['B.object_codeLike']" @input="(val) => (filters['B.object_codeLike'] = val ? val.toUpperCase() : null)"/>
        </v-col>
        <v-col cols="2">
          <v-select :label="tLabel('SysType')" v-model="filters.sysTypeIn" :items="sysTypes" multiple />
        </v-col>

        <v-col cols="2">
          <v-autocomplete clearable :label="tLabel('Tipo Unit')" v-model="filters.type_code" :items="unitTypes" item-text="text" item-value="value"> </v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-select :label="tLabel('Visit status')" v-model="filters.visitStatusIn" :items="visitStatus" multiple />
        </v-col>
        <v-col cols="2">
          <DateTimeAdapter label="Data In Da" defaultTime="00:00:00" v-model="filters['b.data_inAsDateGreatherThan']" />
        </v-col>
        <v-col cols="2">
          <DateTimeAdapter label="Data In A" defaultTime="23:59:59" v-model="filters['b.data_inAsDateLessThan']" />
        </v-col>
        <v-col cols="2">
          <v-select :label="tLabel('Visit role')" v-model="filters.visitRoleIn" :items="roles" multiple />
        </v-col>
        <v-col cols="2">
          <v-text-field :label="tLabel('Voyage In/Out')" v-model="filters.voyagesLike" @input="(val) => (filters.voyagesLike = val ? val.toUpperCase() : null)"/>
        </v-col>
        <v-col cols="2">
          <v-autocomplete :label="tLabel('Owner')" v-model="filters['B.owner_company_id']" :items="owners" item-value="id" item-text="code"/>
        </v-col>
        <v-col cols="2">
          <v-select :label="tLabel('Full/Empty')" v-model="filters['uv.full_empty']" :items="fullEmpty" />
        </v-col>
        <!-- <v-col cols="2">
          <v-select :label="tLabel('Pericoloso')" v-model="filters['uv.dangerous']" :items="dangerousFlg" />
        </v-col> -->
        <v-col cols="2">
          <v-select :label="tLabel('Danni')" v-model="filters.damaged" :items="yesNoList" />
        </v-col>

        <v-col cols="2">
          <v-select :label="tLabel('Pericoloso')" v-model="filters.pericoloso" :items="dangerousFlg" />
        </v-col>
      </template>

      <template v-slot:item-menu="{ item }">
        <v-list>
          <v-list-item @click="goToVisitDetails(item)">
            <v-list-item-title class="text-uppercase">{{ tLabel("View details") }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="secondary">mdi-information-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </InventoryTable>
  </div>
</template>


<script>
import InventoryTable from "@/components/yard/InventoryTable";
import FormatsMixins from "../../../mixins/FormatsMixins";
import DateTimeAdapter from "@/components/anagrafiche/DateTimeAdapter";
import YardEnumMixin from "@/mixins/yard/EnumMixin";

export default {
  name: "AllVisits",
  data() {
    return {
      item: null,
      includeCarrier: false,
      sysTypes: [],
      sysTypesMap: {},
      visitStatus: [],
      visitStatusMap: {},
      roles: [],
      rolesMap: {},
      unitTypes: [],
      owners: [],
      fullEmpty: [
        { text: "FULL", value: "F" },
        { text: "EMPTY", value: "E" },
      ],
      dangerousFlg: [
        { text: "SI", value: true },
        { text: "NO", value: false },
      ],
      yesNoList: [
        { text: "SI", value: "Y" },
        { text: "NO", value: "N" },
      ],
      defaultSort: { field: "data_in", desc: true },
      headers: [
        { text: `${this.tLabel("Type")}`, value: "sysType" },
        { text: `${this.tLabel("Unit")}`, value: "objectCode" },
        { text: `${this.tLabel("Operator")}`, value: "owner" },
        { text: `${this.tLabel("Cycle")}`, value: "cycle" },
        { text: `${this.tLabel("Unit Type")}`, value: "typeCode", sortable: false },
        { text: `${this.tLabel("ISO Size Type")}`, value: "isoSizeType" },
        { text: `${this.tLabel("Items")}`, value: "qty" },
        { text: `${this.tLabel("WGT")}`, value: "grossweight" },
        { text: `${this.tLabel("Status")}`, value: "status" },
        { text: `${this.tLabel("Position Status")}`, value: "positionStatus" },
        { text: `${this.tLabel("Customs Status")}`, value: "customsStatus" },
        { text: `${this.tLabel("Full/Empty")}`, value: "fullEmpty" },
        { text: `${this.tLabel("UNDG")}`, value: "dangerous" },
        { text: `${this.tLabel("Position")}`, value: "positionCode" },
        { text: `${this.tLabel("Order")}`, value: "hinOrderCode" },
        { text: `${this.tLabel("In")}`, value: "hinTransportType", sortable: false },
        { text: `${this.tLabel("Voyage In")}`, value: "hinVoyageCode", sortable: false },
        { text: `${this.tLabel("Date In")}`, value: "dataIn", formatValue: "formatDateTimeFromDate" },
        { text: `${this.tLabel("Out")}`, value: "houtTransportType", sortable: false },
        { text: `${this.tLabel("Voyage Out")}`, value: "houtVoyageCode", sortable: false },
        { text: `${this.tLabel("Planned Date Out")}`, value: "houtPlannedDate", formatValue: "formatDateTimeFromDate", sortable: false },
        { text: `${this.tLabel("Date Out")}`, value: "dataOut", formatValue: "formatDateTimeFromDate" },
      ],
    };
  },
  async mounted() {
    this.loadCombo();
  },
  methods: {
    initFilters(filters) {
      //filters.visitRoleIn=[];
      this.includeCarrier = false;
      this.setCarrierFilters(filters);
    },
    setCarrierFilters(filters) {
      //filters.unitsOnly= this.includeCarrier ? null : true;
      //filters.unitsAndCarrierOnly= this.includeCarrier ? true: null;
    },
    toggle(filters, field) {
      filters[field] = filters[field] ? null : true;
      this.$emit("refreshTable");
    },
    toggleColor(filters, field) {
      return filters[field] ? "primary" : null;
    },
    toggleCarrier(filters) {
      this.includeCarrier = !this.includeCarrier;
      this.setCarrierFilters(filters);
      this.$emit("refreshTable");
    },
    goToVisitDetails(item) {
      console.log("goToVisitDetails item", item.id, item);
      this.$router.push({ path: "/yard/inventory/visitDetails/" + item.id + "/" + item.sysType });
      return;
    },
    async loadCombo() {
      this.unitTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "unit-types/comboList");

      let goodsType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "goods-types/comboList");
      this.unitTypes.push.apply(this.unitTypes, goodsType);

      let roroType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "rorotypes/comboList");
      this.unitTypes.push.apply(this.unitTypes, roroType);

      const all = await this.$api.yardData.getLookups();
      this.sysTypes = this.mapList(this.sysTypesMap, all.sysTypes);
      this.visitStatus = this.mapList(this.visitStatusMap, all.visitStatus);
      this.roles = this.mapList(this.rolesMap, all.roles);
      this.owners= await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
    },
    mapList(map, list) {
      list.forEach((element) => {
        map[element.value] = element.text;
      });
      return list;
    },
  },
  components: { InventoryTable, DateTimeAdapter },
  mixins: [FormatsMixins, YardEnumMixin],
};
</script>
